import React, { useState } from 'react';
import { Link } from "gatsby"

export default function PracticeCategory(props) {
    return (
        <div className={props.className}>
            <div className="category-menu">
                <ul>
                    <React.Fragment>
                        {props.categoryContent.data.map(item => (
                            <li key={item._id} className={item.slug}>
                                <Link title={item.title} to={"/blog-by-category/" + item.title}>
                                    <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                                </Link>
                            </li>
                        ))}
                    </React.Fragment>
                </ul>
            </div>

            <div className="category-menu2">
                <h3 className="archives_title">Archives </h3>
                <ul>
                    {Object.entries(props?.year_count).map(([key, value], index) => (
                        <li className="position-relative" id={key}>

                            {Object.entries(props.year_count).length !== 1 ?
                                <Link title={key} to={"/blog-by-year/" + key}>  <div> Blogs {key} - {value.length} </div>  </Link>
                                : ''
                            }

                        </li>
                    ))}

                </ul>
            </div>


            <div className="our-resources-box">
                <h4> Resources </h4>
                <p>This section provides information about stamp duty and other government policies specific to the states of Telangana and Andhra Pradesh.</p>
                <Link title='View Our Resources' to={"/resources/"}>
                    View Resources
                </Link>
            </div>

        </div>
    )
}
